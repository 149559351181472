@media only screen and (min-width:1100px){
    body{
        background-color: transparent;
        margin:0px;
        padding:0px;
        max-width: 100vw;
    }
    #tso_valentine_timer{
        display:flex;
        position: relative;
        top: 35vh;
        justify-content: center;
    }
    #tso_valentine_flowerfarm{
        position: relative;
        top: 17vh;
        color: var(--textColor);
        text-align: center;
        margin-left: 25px;
        margin-right: 10px;
        background-color: var(--accentColor);
        height: 96px;
        padding: 35px;
        border-radius: 5px;
        border: 3px solid var(--borderColor);
    }
    .flowerfarm{
        width: 340px;
        background-image: url(./img/flowerfarm2.png);
        background-repeat:no-repeat;
        height: 290px;
    }
    #tso_valentine_mini{
        position: relative;
        top: 5px;
        left:5px;
        color: var(--textColor);
        text-align: center;
        background-color: var(--accentColor);
        height: 60px;
        padding: 15px;
        border-radius: 5px;
        border: 3px solid var(--borderColor);
    }
}

@media only screen and (max-width:1100px){
    body{
        background-color: transparent;
        margin:0px;
        padding:0px;
        max-width: 100vw;
    }
    #tso_valentine_timer{
        display: flex;
        justify-content: center;
    }
    #tso_valentine_flowerfarm{
        position: absolute;
        top: 124px;
        width: 100%;
        color: var(--invertedTextColor);
        display: flex;
        justify-content: center;
    }
    .flowerfarm{
        width: 285px;
    }
    #flowerfarm_2{display:none;}
    #flower_frame{
        width: 185px;
        color: black;
        background-color: var(--accentColor);
        border-radius: 4px;
        border: 2px solid var(--borderColor);
        text-align: center;
        font-size: 23px;
        padding: 5px;
    }
}