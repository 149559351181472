@media only screen and (min-width:1100px){
    body{
        background-color: transparent;
        margin:0px;
        padding:0px;
        max-width: 100vw;
    }
    #tso_halloween_timer{
        display:flex;
        position: relative;
        top: 35vh;
        justify-content: center;
    }
    #tso_halloween_cemetaries{
        position: relative;
        top: 7vh;
        color: var(--textColor);
        text-align: center;
        margin-left: 25px;
        margin-right: 10px;
        background-color: var(--accentColor);
        height: 126px;
        padding: 25px;
        border-radius: 5px;
        border: 3px solid var(--borderColor);
    }
    .pumpkin_farm{
        width: 340px;
        background-image: url('./img/S_Pumpkin_Field.webp');
        background-repeat:no-repeat;
        background-size: cover;
        height: 229px;
    }
    #tso_halloween_mini{
        position: relative;
        top: 5px;
        left:5px;
        color: var(--textColor);
        text-align: center;
        background-color: var(--accentColor);
        height: 96px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid var(--borderColor);
    }
}

@media only screen and (max-width:1100px){
    body{
        background-color: transparent;
        margin:0px;
        padding:0px;
        max-width: 100vw;
    }
    #tso_halloween_timer{
        display:block !important;
        text-align: center;
    }
    #tso_halloween_cemetaries{
        position: absolute;
        top: 105px;
        width: 100%;
        color: var(--invertedTextColor);
        text-shadow: 0px 0px 25px blue;
    }
    .pumpkin_farm{
        width: 350px;
        position: relative;
    }
    #pumpkin_farm_2{display:none;}
    #tso_halloween_mini{
        position: relative;
        top: 5px;
        left:5px;
        color: var(--textColor);
        text-align: center;
        background-color: var(--accentColor);
        height: 96px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid var(--borderColor);
    }
}